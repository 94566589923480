@import './mixins';
.header {
  position: relative;
  padding: 45px 0 17% 0;
  background-image: url('../img/header-bg.png');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  @include mobile {
    padding-top: 223px;
    background-image: url('../img/header-bg-mobile.png');
  }
  &__img,
  &__img2 {
    position: absolute;
    right: 0;
    top: 0;
    @include mobile {
      display: none;
    }
  }
  &__img2 {
    left: 0;
  }
  &__nav {
    width: max-content;
    margin-left: auto;
    margin-bottom: 250px;
    @include mobile {
      display: none;
    }
    &_active {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      padding: 85px 0 30px 0;
      display: flex;
      justify-content: center;
      background-color: var(--color-white);
      z-index: 10;
    }
  }
  &__list {
    display: flex;
    align-items: center;
    @include mobile {
      width: 100%;
      flex-direction: column;
    }
  }
  &__item:not(:last-child) {
    margin-right: 50px;
    @include mobile {
      margin: 0 0 20px 0;
    }
  }
  &__item {
    @include mobile {
      position: relative;
      width: 100%;
      text-align: center;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        width: 100%;
        height: 1px;
        background-color: #f1f1f1;
      }
    }
  }
  &__link {
    font: var(--font-mont-400);
    color: var(--color-black);
    transition: .3s ease-in-out;
    &:hover {
      text-decoration: underline;
    }
  }
  &__title {
    margin: 0 0 35px 0;
    font-size: 60px;
    line-height: 73px;
    color: var(--color-black);
    text-align: center;
    @include mobile {
      margin: 0 0 15px 0;
      font-size: 29px;
      line-height: 37px;
    }
  }
  &__subtitle {
    margin: 0;
    font: var(--font-mont-400);
    font-size: 28px;
    line-height: 34px;
    color: var(--color-black);
    text-align: center;
    @include mobile {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.burger {
  display: none;
  @include mobile {
    position: absolute;
    top: 30px;
    display: block;
    margin-right: auto;
    width: 40px;
    height: 4px;
    background-color: var(--color-black);
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: -10px;
      width: 40px;
      height: 4px;
      background-color: var(--color-black);
    }
    &::after {
      top: initial;
      bottom: -10px;
    }
  }
  &_active {
    width: 0;
    z-index: 15;
    &::before {
      top: 0;
      width: 33px;
      height: 2px;
      transform: rotate(45deg);
    }
    &::after {
      bottom: 2px;
      width: 33px;
      height: 2px;
      transform: rotate(-45deg);
    }
  }
}
