.s8 {
  position: relative;
  padding: 112px 0;
  background-image: url('../img/s8-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  &__img {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    @include mobile {
      display: none;
    }
  }
  &__img2 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    @include mobile {
      display: none;
    }
  }
  &__img_mobile {
    display: none;
    @include mobile {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }
  &-content {
    position: relative;
    padding: 92px 0;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 310px;
      height: 4px;
      background-color: var(--color-white);
      border-radius: 4px;
    }
    &::after {
      top: initial;
      bottom: 0;
    }
    &__text {
      width: 55%;
      margin: 0 auto 50px;
      font: var(--font-mont-500);
      font-size: 24px;
      line-height: 32px;
      color: var(--color-white);
      text-align: center;
      @include mobile {
        width: 100%;
        margin: 0 auto 40px;
        font-size: 22px;
        line-height: 32px;
      }
    }
    &__link {
      padding: 23px 97px;
      margin: 0 auto;
      @include mobile {
        padding: 19px 67px;
      }
    }
  }
}
