.s9 {
  padding-top: 100px;
  padding-bottom: 70px;
  @include mobile {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  &__title {
    margin-bottom: 50px;
    text-align: center;
    @include mobile {
      margin-bottom: 30px;
    }
  }
  &__list {
    width: 79%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
    @include tablet {
      width: 100%;
      column-gap: 25px;
    }
    @include mobile {
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto;
      row-gap: 60px;
    }
  }
  &__item {
    img {
      display: block;
      margin: 0 auto 20px;
    }
  }
  &__name {
    margin: 0 0 5px 0;
    font: var(--font-mont-700);
    font-size: 24px;
    line-height: 29px;
    color: #277668;
    text-align: center;
    @include mobile {
      font-size: 22px;
      line-height: 27px;
    }
  }
  &__age {
    margin: 0 0 17px 0;
    font: var(--font-mont-400);
    font-size: 20px;
    line-height: 24px;
    color: var(--color-black);
    text-align: center;
    @include mobile {
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__text {
    margin: 0;
    font: var(--font-mont-400);
    line-height: 24px;
    color: var(--color-black);
    text-align: center;
  }
}
