html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-family: "Open sans", sans-serif;
    background-color: var(--color-white);
  }

  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  .list-reset {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .site-container {
    overflow: hidden;
  }

  .btn-reset {
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .container {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1510px;
    @include mobile {
      min-width: 290px;
    }
  }

  .title {
    margin: 0;
    font: var(--font-mont-700);
    @include mobile {
      font-size: 26px;
      line-height: 32px;
    }
  }

  .btn {
    display: block;
    width: max-content;
    font: var(--font-mont-700);
    font-size: 20px;
    line-height: 24px;
    color: var(--color-white);
    background: linear-gradient(180deg, #277668 0%, #1C554B 100%);
    border-radius: 4px;
    transition: .3s ease-in-out;
    border: none;
  }
  .btn:hover {
    opacity: .7;
  }

