.s6 {
  padding: 220px 0;
  background-image: url('../img/s6-bg.png');
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @include mobile {
    padding: 120px 0;
    background-image: url('../img/s6-bg-mobile.png');
  }
  &-content {
    width: 40%;
    margin-left: 15%;
    @include mobile {
      width: 100%;
      margin-left: 0;
    }
    &__title {
      position: relative;
      margin-bottom: 38px;
      color: var(--color-black);
      text-transform: uppercase;
      @include mobile {
        margin-bottom: 25px;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -15px;
        width: 117px;
        height: 8px;
        background-color: #277668;
        border-radius: 4px;
      }
    }
    &__subtitle {
      margin: 0;
      font: var(--font-mont-400);
      font-size: 24px;
      line-height: 32px;
      color: var(--color-black);
      @include mobile {
        width: 90%;
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}
