.s2 {
  position: relative;
  padding: 150px 0;
  background-image: url('../img/s2-bg.png');
  background-size: cover;
  z-index: 1;
  @include mobile {
    padding: 25px 0;
    background-position: top;
  }
  &__img {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    @include mobile {
      display: none;
    }
  }
  &__img2 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @include mobile {
      display: none;
    }
  }
  &__img_mobile {
    display: none;
    @include mobile {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  &-content {
    width: 90%;
    margin-left: auto;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: repeat(6, auto);
    column-gap: 10px;
    row-gap: 25px;
    @include tablet {
      width: 100%;
      grid-template-columns: 100%;
    }
    &__title {
      position: relative;
      grid-row: 2;
      margin-bottom: 20px;
      grid-column: 1;
      text-transform: uppercase;
      color: var(--color-white);
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -15px;
        width: 117px;
        height: 8px;
        background-color: var(--color-white);
        border-radius: 4px;
      }
    }
    &__text {
      grid-row: 5;
      grid-column: 1;
      margin: 0;
      font: var(--font-mont-500);
      color: var(--color-white);
      @include tablet {
        grid-row: 8;
      }
      &:nth-child(2) {
        grid-row: 3;
        @include tablet {
          grid-row: 6;
        }
      }
      &:nth-child(3) {
        grid-row: 4;
        @include tablet {
          grid-row: 7;
        }
      }
      span {
        font-weight: 700;
      }
    }
    &__img {
      width: 100%;
      grid-column: 2;
      grid-row: 1/7;
      align-self: center;
      @include tablet {
        grid-column: 1;
        grid-row: 3/6;
      }
    }
  }
}
