@mixin desktop {
  @media (max-width: (1400px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (1200px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (768px)) {
    @content;
  }
}
