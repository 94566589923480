.s4 {
  position: relative;
  padding: 77px 0;
  background-image: url('../img/s4-bg.png');
  background-size: cover;
  @include mobile {
    padding-top: 30px;
    padding-bottom: 70px;
    background-position: bottom;
    z-index: 1;
  }
  &__img {
    position: absolute;
    left: 0;
    bottom: 0;
    @include mobile {
      display: none;
    }
  }
  &__img2 {
    position: absolute;
    top: 0;
    right: 0;
    @include mobile {
      display: none;
    }
  }
  &__img_mobile {
    display: none;
    @include mobile {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  }
  &-content {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: repeat(8, auto);
    column-gap: 10px;
    row-gap: 25px;
    @include tablet {
      grid-template-columns: 100%;
      grid-template-rows: repeat(6, auto);
    }
    &__title {
      position: relative;
      grid-column: 2;
      grid-row: 3;
      margin-bottom: 20px;
      text-transform: uppercase;
      color: var(--color-white);
      @include tablet {
        grid-column: 1;
        grid-row: 1;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -15px;
        width: 117px;
        height: 8px;
        background-color: var(--color-white);
        border-radius: 4px;
      }
    }
    &__text {
      grid-row: 4;
      grid-column: 2;
      margin: 0;
      font: var(--font-mont-500);
      color: var(--color-white);
      @include tablet {
        grid-column: 1;
      }
      &:last-child {
        grid-row: 5;
      }
    }
    &__img {
      width: 100%;
      grid-column: 1;
      grid-row: 1/8;
      align-self: center;
      @include tablet {
        grid-row: 2/4;
      }
    }
  }
}
