.s10 {
  padding-top: 70px;
  padding-bottom: 85px;
  @include mobile {
    padding-top: 30px;
    padding-bottom: 40px;
  }
  &__title {
    margin-bottom: 30px;
    text-align: center;
  }
  &-content {
    width: 70%;
    margin: 0 auto;
    @include mobile {
      width: 100%;
    }
    &__text {
      margin: 0 0 20px 0;
      font: var(--font-mont-400);
      font-size: 18px;
      line-height: 32px;
      color: var(--color-black);
      @include mobile {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}
