.s3 {
  position: relative;
  padding: 220px 0;
  z-index: 1;
  @include mobile {
    padding: 40px 0;
  }
  &__img {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    @include mobile {
      display: none;
    }
  }
  &__img2 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    @include mobile {
      display: none;
    }
  }
  &__img_mobile {
    display: none;
    @include mobile {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }
  &__title {
    position: relative;
    width: 55%;
    margin: 0 auto 60px;
    text-align: center;
    text-transform: uppercase;
    @include mobile {
      width: 100%;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -15px;
      margin: 0 auto;
      width: 117px;
      height: 8px;
      background-color: #277668;
      border-radius: 4px;
    }
  }
  &__list {
    width: 67%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    @include tablet {
      width: 100%;
    }
    @include mobile {
      grid-template-columns: 100%;
      row-gap: 10px;
    }
  }
  &__item {
    padding: 30px 20px;
    display: flex;
    align-items: flex-start;
    background-color: #fafafa;
    border-radius: 20px;
    @include mobile {
      flex-direction: column;
    }
    img {
      margin-right: 25px;
      @include mobile {
        margin: 0 auto 28px;
      }
    }
  }
  &-text {
    &__1 {
      margin: 0 0 20px 0;
      font: var(--font-mont-700);
      font-size: 18px;
      line-height: 22px;
      color: var(--color-black);
      @include mobile {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
      }
    }
    &__2 {
      margin: 0;
      font: var(--font-mont-400);
      font-size: 17px;
      line-height: 21px;
      color: var(--color-black);
      @include mobile {
        font-size: 15px;
        line-height: 19px;
        text-align: center;
      }
    }
  }
}
