.footer {
  padding: 70px 0 90px 0;
  @include mobile {
    padding: 60px 0 50px 0;
  }
  background-color: #121212;
  &__title {
    margin: 0 0 80px 0;
    font: var(--font-mont-700);
    font-size: 26px;
    line-height: 18px;
    color: var(--color-gold);
    text-align: center;
    text-transform: uppercase;
  }
  &__list {
    width: max-content;
    margin: 0 auto 37px auto;
    display: flex;
    align-items: center;
    @include mobile {
      width: fit-content;
    }
  }
  &__item:not(:last-child) {
    margin-right: 30px;
  }

  &-offerta__list {
    position: relative;
    margin: 0 0 100px 0;
    text-align: center;
    &::before {
      content: '';
      position: absolute;
      bottom: -60px;
      left: 0;
      width: 100%;
      border: 1px solid var(--color-white);
    }
  }
  &-offerta__link {
    font: var(--font-montserrat-600);
    font-size: 18px;
    line-height: 22px;
    color: var(--color-white);
    @include mobile {
      font-size: 16px;
      line-height: 20px;
    }
  }
  &-law {
    margin: 0 0 50px 0;
    display: flex;
    align-items: center;
    @include tablet {
      flex-direction: column;
      align-items: initial;
    }
    @include mobile {
      margin: 0 0 90px 0;
    }
    &__leftlist {
      width: 500px;
      margin: 0 auto 0 0;
      display: flex;
      flex-wrap: wrap;
      @include tablet {
        margin: 0 0 40px 0;
        text-align: left;
      }
      @include mobile {
        margin: 0 auto 60px auto;
        width: 345px;
      }
    }
    &__leftitem {
      position: relative;
      &:not(:last-child) {
        margin-right: 20px;
        @include mobile {
          margin-right: 8px;
        }
        &::after {
          content: '';
          position: absolute;
          right: -12px;
          height: 100%;
          border: 1px solid var(--color-white);
          @include mobile {
            right: -6px;
          }
        }
      }
      &:nth-child(3) {
        &::after {
          display: none;
        }
      }
      @include mobile {
        &:nth-child(4),
        &:nth-child(5) {
          width: 100%;
          text-align: center;
          margin-right: 0;
          &::after {
            display: none;
          }
        }
        &:nth-child(3) {
          margin-right: 0;
        }
      }
    }
    &__leftlink {
      font: var(--font-mont-600);
      font-size: 17px;
      line-height: 22px;
      color: var(--color-white)
    }
    &__rightlist {
      width: 45%;
      text-align: right;
      @include tablet {
        width: 100%;
      }
      @include mobile {
        text-align: left;
      }
    }
    &__rightitem,
    &__rightlink {
      font: var(--font-mont-600);
      font-size: 17px;
      line-height: 22px;
      color: var(--color-white);
      @include mobile {
        text-align: center;
      }
    }
  }
  &__C {
    margin: 0;
    font: var(--font-mont-700);
    font-weight: 100;
    font-size: 18px;
    line-height: 18px;
    color: var(--color-white);
    text-align: center;
    @include mobile {
      font-size: 14px;
    }
  }
}

