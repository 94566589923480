:root {
  //colors
  --color-white: #ffffff;
  --color-black: #000000;
  //fonts
  --font-family-mont: "Montserrat", sans-serif;
  --font-mont-400: 400 16px/20px var(--font-family-mont);
  --font-mont-500: 500 20px/24px var(--font-family-mont);
  --font-mont-600: 600 18px/22px var(--font-family-mont);
  --font-mont-700: 700 32px/39px var(--font-family-mont);
}
