.s1 {
  padding: 55px 0;
  @include mobile {
    padding: 0;
    margin-bottom: 35px;
  }
  &-content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    @include tablet {
      width: 100%;
    }
    @include mobile {
      flex-direction: column-reverse;
    }
    &__text {
      margin: 0 auto 0 0;
      width: 60%;
      font: var(--font-mont-400);
      font-size: 18px;
      line-height: 22px;
      color: var(--color-black);
      @include tablet {
        width: 50%;
      }
      @include mobile {
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
      }
    }
    &__link {
      padding: 22px 133px;
      @include mobile {
        padding: 18px 103px;
        margin-bottom: 25px;
      }
    }
  }
}
