.s7 {
  position: relative;
  padding: 120px 0 80px 0;
  z-index: 1;
  &__img {
    position: absolute;
    left: 0;
    top: 5%;
    z-index: -1;
    @include mobile {
      display: none;
    }
  }
  &__img2 {
    position: absolute;
    right: 0;
    top: 5%;
    z-index: -1;
    @include mobile {
      display: none;
    }
  }
  &__title {
    position: relative;
    margin-bottom: 48px;
    color: var(--color-black);
    text-align: center;
    text-transform: uppercase;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -15px;
      margin: 0 auto;
      width: 117px;
      height: 8px;
      background-color: #277668;
      border-radius: 4px;
    }
  }
  &-content {
    width: 66%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    @include tablet {
      width: 100%;
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
      row-gap: 70px;
    }
    &__img {
      margin-bottom: 25px;
      @include tablet {
        display: block;
        margin: 0 auto 20px;
      }
    }
    &__title {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 30px;
      color: var(--color-black);
      @include tablet {
        max-width: 480px;
        margin: 0 auto 10px;
        font-size: 22px;
        line-height: 27px;
      }
    }
    &__list {
      margin-bottom: 53px;
      @include tablet {
        max-width: 480px;
        margin: 0 auto 53px;
      }
    }
    &__item {
      display: flex;
      align-items: flex-start;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      img {
        margin-right: 15px;
      }
    }
    &__text {
      margin: 0;
      padding-top: 5px;
      font: var(--font-mont-400);
      font-size: 18px;
      line-height: 22px;
      color: var(--color-black);
    }
    &__link {
      padding: 23px 88px;
      margin: 0 auto 33px;
      @include mobile {
        padding: 19px 69px;
        font-size: 18px;
        line-height: 22px;
      }
      &:disabled {
        opacity: .5;
      }
    }
  }
  &-price {
    margin-bottom: 33px;
    display: flex;
    align-items: center;
    @include tablet {
      max-width: 480px;
      margin: 0 auto 23px;
    }
    &__newprice,
    &__oldprice {
      margin-right: 20px;
      font: var(--font-mont-700);
      font-size: 24px;
      line-height: 30px;
      color: #277668;
    }
    &__oldprice {
      position: relative;
      margin: 0;
      font-weight: 500;
      color: #999999;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 15px;
        width: 100%;
        height: 2px;
        background-color: #277668;
      }
    }
  }
}

.checkbox-custom {
  display: block;
  width: 95%;
  font: var(--font-mont-400);
  font-size: 12px;
  line-height: 15px;
  color: var(--color-black);
  @include tablet {
    max-width: 480px;
    margin: 0 auto;
  }
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.checkbox-custom input {
  position: absolute;
  display: none;
  opacity: 0;
}

.checkbox-custom span {
  position: relative;
  padding-top: 5px;
  padding-left: 50px;
  width: 95%;
  display: block;
  cursor: pointer;
  a {
    color: var(--color-black);
    text-decoration: underline;
  }
}

.checkbox-custom span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 34px;
  height: 34px;
  border: 1px solid #B5B5B5;
  border-radius: 6px;
  background-color: var(--color-white);
}

.checkbox-custom span::after {
  content: url("../img/checked.png");
  position: absolute;
  top: 7px;
  left: 3px;
  opacity: 0;
}

.checkbox-custom input:checked + span::after {
  opacity: 1;
}

.disabled {
  pointer-events: none;
  background: #B5B5B5;
}
