.s5 {
  padding: 100px 0;
  background-image: url('../img/s5-bg.png');
  background-position: right bottom;
  background-repeat: no-repeat;
  @include mobile {
    padding: 50px 0;
    background-image: none;
  }
  &__list {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 55px;
    @include tablet {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
    }
    @include mobile {
      grid-template-columns: 100%;
      grid-template-rows: repeat(6, 1fr);
      row-gap: 30px;
    }
  }
  &__item {
    @include tablet{
      margin: 0 auto;
    }
    img {
      margin-bottom: 30px;
      @include mobile {
        display: block;
        margin: 0 auto 15px;
      }
    }
  }
  &__text1,
  &__text2 {
    margin: 0 0 25px 0;
    font: var(--font-mont-600);
    color: var(--color-black);
    @include mobile {
      max-width: 380px;
      margin: 0 auto 15px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__text2 {
    margin: 0;
    font-weight: 400;
    @include tablet {
      max-width: 380px;
    }
    @include mobile {
      margin: 0 auto;
    }
  }
}
